import React, { useState, useEffect } from "react";
import { BrandLogo, Logo } from "../../components/logo/Logo";
import LogoDrak2x from "../../images/logo-dark2x.png";
import LogoLight2x from "../../images/logo2x.png";
import { Col, Container, Row } from "reactstrap";
import {
  Header,
  HeaderWrap,
  HeaderBrand,
  HeaderContent,
  HeaderMain,
} from "../../layout/header/Header";
import { Link } from "../../components/button/Button";
import Menu from "../../layout/menu/Menu";
import MobileMenu from "../../layout/menu/MobileMenu";
import {
  HeaderCaption,
  HeaderText,
  HeaderTitle,
} from "../../components/headerCaption/HeaderCaption";
import { HeaderImage } from "../../components/images/Images";

import HeaderImg from "../../images/header/iphone.png";

import { BannerTwoData } from "./BannerData";

const BannerTwo = (props) => {
  const [toggle, setToggle] = useState(false);
  const [offset, setOffset] = useState(0);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
    window.scrollTo(0, 0);
    viewChange();
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  return (
    <Header
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <HeaderMain
        className={`header-main header-main-s1 is-sticky is-transparent ${
          offset > 0 ? "has-fixed" : ""
        }`}
      >
        <Container className="header-container">
          <HeaderWrap>
            <div className="header-logo d-flex ">
              <Logo to="/" dark={LogoDrak2x} light={LogoLight2x} />

              <span className=" h3 mx-2">Paygram</span>
            </div>
            <div className="header-toggle" onClick={() => setToggle(!toggle)}>
              <button
                className={`menu-toggler ${toggle === true ? "active" : ""}`}
                data-target="mainNav"
              >
                <em className="menu-on icon ni ni-menu"></em>
                <em className="menu-off icon ni ni-cross"></em>
              </button>
            </div>
            <nav
              className={`header-menu menu ${toggle === true ? "active" : ""} ${
                mobileView ? "mobile-menu" : ""
              }`}
            >
              {!mobileView ? (
                <Menu className="ms-lg-auto" data={BannerTwoData} />
              ) : (
                <MobileMenu data={BannerTwoData} />
              )}
              <ul className="menu-btns">
                <li>
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.paygram.clientApp&pcampaignid=web_share"
                    target="_blank"
                    rel="noreferrer"
                    className="btn-primary btn-lg"
                  >
                    Download App
                  </Link>
                </li>
              </ul>
            </nav>
            {toggle && window.innerWidth < 992 && (
              <div
                className="header-overlay"
                onClick={() => setToggle(!toggle)}
              ></div>
            )}
          </HeaderWrap>
        </Container>
      </HeaderMain>
      <HeaderContent className="my-auto py-5">
        <Container>
          <Row className="flex-lg-row-reverse align-items-center justify-content-between g-gs">
            <Col lg="6" className="mb-n3 mb-lg-0">
              <HeaderImage className="header-img header-image-s2">
                <img src={HeaderImg} class="img-fluid" alt="" />
              </HeaderImage>
            </Col>
            <Col lg="5" md="10">
              <HeaderCaption>
                <HeaderTitle>Unleash Financial Freedom</HeaderTitle>
                <HeaderText>
                  <p>
                    Empower your finances with Paygram—your all-in-one solution
                    for seamless budgeting, smart investments, and financial
                    success. Take control, track your wealth, and thrive
                    financially, effortlessly.
                  </p>
                </HeaderText>
                <ul className="header-action btns-inline">
                  <li>
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.paygram.clientApp&pcampaignid=web_share"
                      target="_blank"
                      className="btn-primary btn-lg"
                    >
                      <span>DownLoad App</span>
                    </Link>
                  </li>
                </ul>
              </HeaderCaption>
            </Col>
          </Row>
        </Container>
      </HeaderContent>
    </Header>
  );
};

export default BannerTwo;
