import React, { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import IndexOne from "./pages/IndexOne";

import IndexFive from "./pages/IndexFive";

import Error404Modern from "./pages/error/404-modern";

function App() {
  useEffect(() => {
    document.body.className =
      "nk-body bg-white npc-landing no-touch nk-nio-theme";
  }, []);

  return (
    <Suspense fallback={<div />}>
      <div className="nk-app-root">
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}`}>
            <Route path={`contact`} element={<IndexOne />} />

            <Route index element={<IndexFive />} />

            <Route path="*" element={<Error404Modern />}></Route>
          </Route>
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
