import React from "react";
import BannerOne from "../section/banner/BannerOne";
import CtaOne from "../section/cta/CtaOne";
import FooterOne from "../section/footer/FooterOne";

const IndexOne = () => {
  return (
    <div className="nk-main">
      <BannerOne
        className="has-header-main-s1 bg-grad-a mb-5 mb-sm-6 mb-md-7"
        id="#home"
      />
      <CtaOne className="secton-cta bg-lighter" />
      <FooterOne className="bg-dark is-dark" id="#footer" />
    </div>
  );
};

export default IndexOne;
