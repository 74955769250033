export const createSupportMessage = async (reqData) => {
  try {
    const response = await fetch(
      "https://api.thepaygram.com/api/v1/client/support/message",
      {
        method: "POST",
        body: JSON.stringify(reqData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { message } = await response.json();
    return message;
  } catch (error) {
    return "Something went wrong";
  }
};
