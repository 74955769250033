import React from "react";

import { FeatureFour, FeatureFourAlt } from "../section/feature/FeatureFour";
import FooterTwo from "../section/footer/FooterTwo";

import BannerTwo from "../section/banner/BannerTwo";
import Servicetwo from "../section/service/ServiceTwo";
import FaqOne from "../section/faq/FaqOne";
import FooterOne from "../section/footer/FooterOne";

const IndexFive = (props) => {
  return (
    <div className="nk-main">
      <BannerTwo className="header has-header-main-s1 bg-lighter" id="#home" />
      <Servicetwo className="section section-service pb-0" id="#service" />
      <FeatureFour className="section-feature pb-0" id="#feature" />
      <FeatureFourAlt className="section-feature" />
      <FaqOne className="section-faq" id="#faqs" />
      <FooterOne className="bg-dark is-dark" id="#footer" />
    </div>
  );
};

export default IndexFive;
