import React from 'react'

const Contents = () => {
  return (
    <svg x="0" y="0" fill="currentColor" viewBox="0 0 512 512">
      <path d="M160 128H32c-17.6 0-32-14.4-32-32V32C0 14.4 14.4 0 32 0h128c17.6 0 32 14.4 32 32v64c0 17.6-14.4 32-32 32zM32 21.3c-5.9 0-10.7 4.8-10.7 10.7v64c0 5.9 4.8 10.7 10.7 10.7h128c5.9 0 10.7-4.8 10.7-10.7V32c0-5.9-4.8-10.7-10.7-10.7H32zM160 320H32c-17.6 0-32-14.4-32-32v-64c0-17.6 14.4-32 32-32h128c17.6 0 32 14.4 32 32v64c0 17.6-14.4 32-32 32zM32 213.3c-5.9 0-10.7 4.8-10.7 10.7v64c0 5.9 4.8 10.7 10.7 10.7h128c5.9 0 10.7-4.8 10.7-10.7v-64c0-5.9-4.8-10.7-10.7-10.7H32zM480 512H32c-17.6 0-32-14.4-32-32v-64c0-17.6 14.4-32 32-32h448c17.6 0 32 14.4 32 32v64c0 17.6-14.4 32-32 32zM32 405.3c-5.9 0-10.7 4.8-10.7 10.7v64c0 5.9 4.8 10.7 10.7 10.7h448c5.9 0 10.7-4.8 10.7-10.7v-64c0-5.9-4.8-10.7-10.7-10.7H32zM501.3 42.7h-256c-5.9 0-10.7-4.8-10.7-10.7s4.8-10.7 10.7-10.7h256c5.9 0 10.7 4.8 10.7 10.7s-4.8 10.7-10.7 10.7zM501.3 106.7h-256c-5.9 0-10.7-4.8-10.7-10.7s4.8-10.7 10.7-10.7h256c5.9 0 10.7 4.8 10.7 10.7s-4.8 10.7-10.7 10.7zM501.3 234.7h-256c-5.9 0-10.7-4.8-10.7-10.7s4.8-10.7 10.7-10.7h256c5.9 0 10.7 4.8 10.7 10.7s-4.8 10.7-10.7 10.7zM501.3 298.7h-256c-5.9 0-10.7-4.8-10.7-10.7s4.8-10.7 10.7-10.7h256c5.9 0 10.7 4.8 10.7 10.7s-4.8 10.7-10.7 10.7z"></path>
    </svg>
  )
}

export default Contents
