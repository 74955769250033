import React, { useState, useEffect } from "react";
import HeaderImg from "../../images/header/gfx-c.png";
import LogoDrak2x from "../../images/logo-dark2x.png";
import LogoLight2x from "../../images/logo2x.png";
import Menu from "../../layout/menu/Menu";
import MobileMenu from "../../layout/menu/MobileMenu";
import { Logo } from "../../components/logo/Logo";
import { Col, Container, Row } from "reactstrap";
import {
  Header,
  HeaderWrap,
  HeaderContent,
  HeaderMain,
} from "../../layout/header/Header";
import { Link } from "../../components/button/Button";
import {
  HeaderCaption,
  HeaderText,
  HeaderTitle,
} from "../../components/headerCaption/HeaderCaption";
import { HeaderImage } from "../../components/images/Images";
import { BannerOneData, BannerTwoData } from "./BannerData";

const BannerOne = (props) => {
  const [toggle, setToggle] = useState(false);
  const [offset, setOffset] = useState(0);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
    window.scrollTo(0, 0);
    viewChange();
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  return (
    <Container className="header-container">
      <HeaderWrap>
        <div className="header-logo d-flex ">
          <Logo to="/" dark={LogoDrak2x} light={LogoLight2x} />

          <span className=" h3 mx-2">Paygram</span>
        </div>
        <div className="header-toggle" onClick={() => setToggle(!toggle)}>
          <button
            className={`menu-toggler ${toggle === true ? "active" : ""}`}
            data-target="mainNav"
          >
            <em className="menu-on icon ni ni-menu"></em>
            <em className="menu-off icon ni ni-cross"></em>
          </button>
        </div>
        <nav
          className={`header-menu menu ${toggle === true ? "active" : ""} ${
            mobileView ? "mobile-menu" : ""
          }`}
        >
          {!mobileView ? (
            <Menu className="ms-lg-auto" data={BannerTwoData} />
          ) : (
            <MobileMenu data={BannerTwoData} />
          )}
          <ul className="menu-btns">
            <li>
              <Link
                to="/"
                target="_blank"
                rel="noreferrer"
                className="btn-primary btn-lg"
              >
                Download App
              </Link>
            </li>
          </ul>
        </nav>
        {toggle && window.innerWidth < 992 && (
          <div
            className="header-overlay"
            onClick={() => setToggle(!toggle)}
          ></div>
        )}
      </HeaderWrap>
    </Container>
  );
};

export default BannerOne;
