import React from 'react'

const Analytics = () => {
  return (
    <svg x="0" y="0" fill="currentColor" viewBox="0 0 512 512">
      <path d="M502 452h-26V342.2c0-5.5-4.5-10-10-10s-10 4.5-10 10V452h-52V142.3h52v109.8c0 5.5 4.5 10 10 10s10-4.5 10-10V132.3c0-5.5-4.5-10-10-10h-72c-5.5 0-10 4.5-10 10V452h-24V207.7c0-5.5-4.5-10-10-10h-72c-5.5 0-10 4.5-10 10V452h-24.3V327c0-5.5-4.5-10-10-10h-72c-5.5 0-10 4.5-10 10v125H128v-62c0-5.5-4.5-10-10-10H46c-5.5 0-10 4.5-10 10v62H10c-5.5 0-10 4.5-10 10v40c0 5.5 4.5 10 10 10h492c5.5 0 10-4.5 10-10v-40c0-5.5-4.5-10-10-10zM288 217.7h52V452h-52V217.7zM171.7 337h52v115h-52V337zM56 400h52v52H56v-52zm436 92H20v-20h472v20zM46 180.2h43.1c39.8 0 79.8-4.5 118.6-13.3 93.6-21.3 179.4-67.2 248.9-133v5.7c0 5.5 4.5 10 10 10s10-4.5 10-10V10c0-5.5-4.5-10-10-10h-29.5c-5.5 0-10 4.5-10 10s4.5 10 10 10h5.1c-66.9 63-149.1 106.9-238.9 127.3-37.4 8.5-75.8 12.8-114.2 12.8H46c-5.5 0-10 4.5-10 10s4.5 10.1 10 10.1z"></path>
      <path d="M466 287.2c-2.6 0-5.2 1.1-7.1 2.9-1.9 1.9-2.9 4.4-2.9 7.1 0 2.6 1.1 5.2 2.9 7.1 1.9 1.9 4.4 2.9 7.1 2.9s5.2-1.1 7.1-2.9c1.9-1.9 2.9-4.4 2.9-7.1 0-2.6-1.1-5.2-2.9-7.1-1.9-1.9-4.5-2.9-7.1-2.9z"></path>
    </svg>
  )
}

export default Analytics
