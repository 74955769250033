import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { ImageBlock } from "../../components/images/Images";
import HeaderImg from "../../images/header/iphone_send.png";
import { Section } from "../../layout/section/Section";

import thumbImgTwo from "../../images/gfx/h.png";
import thumbImgOne from "../../images/header/merchants.png";
import { TextBlock, TitleH2 } from "../../components/textBlock/TextBlock";
import { StyledIcon } from "../../components/icon/Icon";
import { ServiceText } from "../../components/service/Service";
import {
  Analytics,
  Idea,
  Badge,
  Box,
  Edit,
  Contents,
  Tools,
} from "../../components/svg/Icon";

const FeatureFour = (props) => {
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="align-items-center g-gs">
          <Col lg="6">
            <ImageBlock className="header-img img-block-s2 pe-xl-6 pe-lg-3">
              <img src={HeaderImg} alt="feature-img" />
            </ImageBlock>
          </Col>
          <Col lg="6">
            <TextBlock>
              <TitleH2>Join us as Paygram Agent</TitleH2>
              <div className="mt-4 ms-n3 ms-sm-n4">
                <Row className="gy-gs">
                  <Col xs="12">
                    <Card className="service service-inline service-s4 after-bg-danger">
                      <div className="card-inner">
                        <StyledIcon className="service-icon styled-icon-s4 styled-icon-5x text-danger">
                          <Box />
                        </StyledIcon>
                        <ServiceText>
                          <h5 className="title">Easy to customers</h5>
                          <p>
                            Our online system makes it easy for customers to
                            withdraw and deposit cash.
                          </p>
                        </ServiceText>
                      </div>
                    </Card>
                  </Col>
                  <Col xs="12">
                    <Card className="service service-inline service-s4 after-bg-primary">
                      <div className="card-inner">
                        <StyledIcon className="service-icon styled-icon-s4 styled-icon-5x text-primary">
                          <Badge />
                        </StyledIcon>
                        <ServiceText>
                          <h5 className="title">Earn Commissions</h5>
                          <p>
                            Let paygram work for you. Earn commissions on every
                            deposit and withdrawal transaction.
                          </p>
                        </ServiceText>
                      </div>
                    </Card>
                  </Col>
                  <Col xs="12">
                    <Card className="service service-inline service-s4 after-bg-pink">
                      <div className="card-inner">
                        <StyledIcon className="service-icon styled-icon-s4 styled-icon-5x text-pink">
                          <Idea />
                        </StyledIcon>
                        <ServiceText>
                          <h5 className="title">Powerful Bookeeping</h5>
                          <p>
                            Keep track of your transactions and monitor your
                            daily sales. With precise reporting, you can make
                            informed decisions.
                          </p>
                        </ServiceText>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </TextBlock>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

const FeatureFourAlt = (props) => {
  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="flex-row-reverse align-items-center justify-content-between g-gs">
          <Col xl="6" lg="5">
            <ImageBlock className=" header-img ps-xl-6">
              <img src={thumbImgOne} alt="feature-img" />
            </ImageBlock>
          </Col>

          <Col xl="6" lg="7">
            <TextBlock>
              <TitleH2 className="text-dark text-center text-lg-start">
                Manage your business with our merchant software
              </TitleH2>
              <Row className="g-gs justify-content-center text-center">
                <Col xs="6">
                  <Card className="service service-s4">
                    <div className="card-inner">
                      <StyledIcon className="service-icon styled-icon-4x text-primary">
                        <Edit />
                      </StyledIcon>
                      <ServiceText>
                        <h5 className="title">Easy to track</h5>
                        <p>
                          Track your daily sales, With informative reports and
                          charts.
                        </p>
                      </ServiceText>
                    </div>
                  </Card>
                </Col>
                <Col xs="6">
                  <Card className="service service-s4 after-bg-danger">
                    <div className="card-inner">
                      <StyledIcon className="service-icon styled-icon-4x text-danger">
                        <Analytics />
                      </StyledIcon>
                      <ServiceText>
                        <h5 className="title">Analytics Tool</h5>
                        <p>
                          Analysis tools that help you keep track of cost and
                          profits earned.
                        </p>
                      </ServiceText>
                    </div>
                  </Card>
                </Col>
                <Col xs="6">
                  <Card className="service service-s4 after-bg-pink">
                    <div className="card-inner">
                      <StyledIcon className="service-icon styled-icon-4x text-pink">
                        <Tools />
                      </StyledIcon>
                      <ServiceText>
                        <h5 className="title">Professionals Tools</h5>
                        <p>
                          We ensure secure and reliable end to end payment
                          between you and your customers.
                        </p>
                      </ServiceText>
                    </div>
                  </Card>
                </Col>
                <Col xs="6">
                  <Card className="service service-s4 after-bg-purple">
                    <div className="card-inner">
                      <StyledIcon className="service-icon styled-icon-4x text-purple">
                        <Contents />
                      </StyledIcon>
                      <ServiceText>
                        <h5 className="title">Ready Content</h5>
                        <p>
                          Easy to integrate with your existing website and
                          employees.
                        </p>
                      </ServiceText>
                    </div>
                  </Card>
                </Col>
              </Row>
            </TextBlock>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export { FeatureFour, FeatureFourAlt };
