import React from 'react'

const Edit = () => {
  return (
    <svg x="0" y="0" fill="currentColor" viewBox="0 0 512 512">
      <path d="M397.7 78.4c6.8 0 12.4-5.5 12.4-12.4V27c0-14.9-12.1-27-27-27H121.6c-3.3 0-6.4 1.3-8.7 3.6L10.5 106c-2.3 2.3-3.6 5.5-3.6 8.7V485c0 14.9 12.1 27 27 27H383c14.9 0 27-12.1 27-27V296.3c0-6.8-5.5-12.4-12.4-12.4-6.8 0-12.4 5.5-12.4 12.4V485c0 1.3-1 2.3-2.3 2.3h-349c-1.3 0-2.3-1-2.3-2.3V127.1H107c14.9 0 27-12.1 27-27V24.7h249.1c1.3 0 2.3 1 2.3 2.3v39c0 6.8 5.5 12.4 12.3 12.4zm-288.4 21.7c0 1.3-1 2.3-2.3 2.3H49.1l60.2-60.2v57.9z"></path>
      <path d="M492.9 100.4l-14.5-14.5c-16.3-16.3-42.8-16.3-59.1 0L303.8 201.3H103.6c-6.8 0-12.4 5.5-12.4 12.4s5.5 12.4 12.4 12.4H279l-74.4 74.4h-101c-6.8 0-12.4 5.5-12.4 12.4s5.5 12.4 12.4 12.4H180l-.2.2c-1.5 1.5-2.6 3.4-3.2 5.4l-19.1 68.7h-53.9c-6.8 0-12.4 5.5-12.4 12.4s5.5 12.4 12.4 12.4h63.3s2.7-.3 3.1-.4c.1 0 78-21.6 78-21.6 2.1-.6 3.9-1.7 5.4-3.2l239.4-239.4c16.4-16.6 16.4-43.1.1-59.4zM184.6 394.1l10.1-36.3L221 384l-36.4 10.1zm60.1-21.3L206 334l197.6-197.6 38.7 38.7-197.6 197.7zm230.7-230.7l-15.6 15.6-38.7-38.7 15.6-15.6c6.7-6.7 17.5-6.7 24.2 0l14.5 14.5c6.7 6.6 6.7 17.5 0 24.2z"></path>
    </svg>
  )
}

export default Edit
