import React, { useState } from "react";
import { Collapse } from "reactstrap";

const Accordion = ({ className, variation, ...props }) => {
  const [isOpen, setIsOpen] = useState("1");

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  return (
    <div
      className={[
        `accordion${variation ? " accordion-s" + variation : ""}${
          className ? " " + className : ""
        }`,
      ]}
    >
      <div className="accordion-item">
        <div
          className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]}
          onClick={() => toggleCollapse("1")}
        >
          <h6 className="title">Is this a secure site for payment?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse
          className="accordion-body"
          isOpen={isOpen === "1" ? true : false}
        >
          <div className="accordion-inner">
            <p>
              Absolutely! We use end to end encryption which guarantees your
              safety and security. All billing information is stored securly on
              our servers.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div
          className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]}
          onClick={() => toggleCollapse("2")}
        >
          <h6 className="title">What are the benefits of using Paygram?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse
          className="accordion-body"
          isOpen={isOpen === "2" ? true : false}
        >
          <div className="accordion-inner">
            <p>
              It is secure and you no longer have to worry about losing your
              money. You can also track your transactions and get a detailed
              report of your transactions.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div
          className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]}
          onClick={() => toggleCollapse("3")}
        >
          <h6 className="title">How do i find agents?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse
          className="accordion-body"
          isOpen={isOpen === "3" ? true : false}
        >
          <div className="accordion-inner">
            <p>
              Track agent locations on the map and get directions to the nearest
              agent. You can also contact our customer care for assistance.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div
          className={[`accordion-head${isOpen !== "4" ? " collapsed" : ""}`]}
          onClick={() => toggleCollapse("4")}
        >
          <h6 className="title">Is paygram good for my business?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse
          className="accordion-body"
          isOpen={isOpen === "4" ? true : false}
        >
          <div className="accordion-inner">
            <p>
              Of course! Paygram is a great way to increase your sales and
              improve your customer experience. You can also earn commissions on
              every transaction.
            </p>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div
          className={[`accordion-head${isOpen !== "5" ? " collapsed" : ""}`]}
          onClick={() => toggleCollapse("5")}
        >
          <h6 className="title">How do I keep track of transactions?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse
          className="accordion-body"
          isOpen={isOpen === "5" ? true : false}
        >
          <div className="accordion-inner">
            <p>
              You can track your transactions on the app. You can also get a
              detailed report of your transactions.
            </p>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Accordion;
