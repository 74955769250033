import React, { useState } from "react";
import { Section, SectionHead } from "../../layout/section/Section";
import { Container, Row, Col, Card, Form, Input } from "reactstrap";
import { Link } from "../../components/button/Button";
import { createSupportMessage } from "../../data/api";
import { Spinner, Alert } from "reactstrap";
import { StyledIcon } from "../../components/icon/Icon";
import { set } from "react-hook-form";

const CtaOne = (props) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const message = await createSupportMessage(formData);
    setLoading(false);
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage("");
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    }, 3000);
  };

  return (
    <Section
      className={props.className && props.className}
      id={props.id && props.id}
    >
      <Container>
        <Row className="justify-content-center">
          <Col xl="12">
            <Row className="align-items-center g-0">
              <Col md="12">
                <Card className="card-shadow round-xl ms-lg-n7 ms-md-n5 mx-4 me-md-0 mt-md-0 mt-n4">
                  <div className="card-inner card-inner-lg">
                    <div className="form-block">
                      <SectionHead className="section-head-sm">
                        <h4 className="title">
                          Do you have any other question?
                        </h4>
                      </SectionHead>
                      <Form onSubmit={handleSubmit} className="form-submit">
                        {successMessage ? (
                          <div className="mb-3">
                            <Alert color="success" className="alert-icon">
                              <StyledIcon name="alert-circle" />{" "}
                              {successMessage}
                            </Alert>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <Row className="g-4">
                          <Col xs="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="name">
                                Your Name
                              </label>
                              <div className="form-control-wrap">
                                <Input
                                  type="text"
                                  className="form-control form-control-lg"
                                  id="name"
                                  placeholder="Your Name"
                                  value={formData.name}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      name: e.target.value,
                                    })
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="name">
                                Enter Your Email
                              </label>
                              <div className="form-control-wrap">
                                <Input
                                  type="text"
                                  className="form-control form-control-lg"
                                  id="email"
                                  placeholder="Enter Your Email"
                                  value={formData.email}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      email: e.target.value,
                                    })
                                  }
                                  required
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="question">
                                Your Question
                              </label>
                              <div className="form-control-wrap">
                                <textarea
                                  className="form-control no-resize"
                                  id="question"
                                  placeholder="Your Question"
                                  value={formData.message}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      message: e.target.value,
                                    })
                                  }
                                ></textarea>
                              </div>
                            </div>
                          </Col>
                          <Col xs="12">
                            {loading ? (
                              <Spinner size="sm" color="light" />
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-lg btn-primary"
                              >
                                Ask Question
                              </button>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default CtaOne;
