const SocialIconOne = [
  { icon: "twitter", to: "/" },
  { icon: "facebook-f", to: "/" },
  { icon: "instagram", to: "/" },
  { icon: "pinterest", to: "/" },
];

const SocialIconFour = [
  { icon: "facebook-f", to: "/" },
  { icon: "instagram", to: "/" },
  { icon: "twitter", to: "/" },
];

const LinkData = [
  { text: "How It Works", to: "/" },
  { text: "Service", to: "/" },
  { text: "Help", to: "/contact" },
  { text: "Contact", to: "/contact" },
];

const LinkDataThree = [
  { text: "Learning", to: "/" },
  { text: "Support center", to: "/" },
  { text: "Frequent questions", to: "/" },
  { text: "Terms of Service", to: "/" },
  { text: "Privacy policy", to: "/" },
  { text: "Sass Service", to: "/" },
  { text: "Knowledge Center", to: "/" },
  { text: "Contact Us", to: "/" },
];
const LinkDataFour = [
  { text: "All Preview", to: "/" },
  { text: "Features", to: "/" },
  { text: "Doc", to: "/" },
  { text: "Need Help?", to: "/" },
];

const LinkRecourse = [
  { text: "Contract US", to: "/" },
  { text: "Career", to: "/" },
  { text: "Support", to: "/" },
  { text: "Press/News", to: "/" },
];
const LinkCompany = [
  { text: "About Us", to: "/" },
  { text: "Team", to: "/" },
  { text: "FAQ", to: "/" },
  { text: "Blog", to: "/" },
];
const LinkProduct = [
  { text: "Integration", to: "/" },
  { text: "API", to: "/" },
  { text: "Roadmap", to: "/" },
  { text: "Pricing", to: "/" },
];

export {
  SocialIconOne,
  SocialIconFour,
  LinkData,
  LinkDataThree,
  LinkDataFour,
  LinkCompany,
  LinkProduct,
  LinkRecourse,
};
